<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group label="สถานะคิวจอง">
            <b-form-radio-group
              @change="load_data"
              v-model="form.order_status"
              :options="updatedLockOptions"
              name="radios-stacked3"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <filter-table :filter_data="filter_data"></filter-table>
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormGroup,
  BCard,
  BCardText,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import FilterTable from "../component/FilterTable.vue";
import moment from "moment";
import store from "@/store/index";
import _ from "lodash";

export default {
  components: {
    BFormRadioGroup,
    BFormGroup,
    BCard,
    BCardText,
    BRow,
    BCol,
    BButton,
    VueGoodTable,
    FilterTable,
  },
  computed: {
    updatedLockOptions() {
      return this.lock_options.map((option) => {
        if (option.value.includes("not-response-no-lock")) {
          return {
            ...option,
            text: `${option.text} (${this.not_response_count})`,
          };
        } else {
          return option;
        }
      });
    },
  },
  data() {
    return {
      text: this.$route.params.text,
      filter_data: [],
      form: {
        side: "status",
        order_status: ["not-response"],
        selected_page: 1,
        per_page: 100,
      },
      lock_options: [
        { text: "ล็อคคิว", value: ["not-response"] },
        { text: "ไม่ได้ล็อคคิว", value: ["not-response-no-lock"] },
      ],
      not_response_count: null,
    };
  },
  created() {
    this.load_data();
    this.order_count();
  },
  methods: {
    load_data() {
      this.$http({
        method: "POST",
        url: `/order/list/filter`,
        data: this.form,
      }).then((x) => {
        this.filter_data = x.data.data;
      });
    },
    async order_count() {
      await this.$http({
        method: "GET",
        url: `/dashboard/order-status-count`,
      }).then((x) => {
        const zz = x.data.data;
        const filterCondition = "not-response-no-lock";
        // const filteredArray = zz.filter((item) => item.req === filterCondition);
        const filteredArray = _.find(zz, { req: filterCondition });
        this.not_response_count = filteredArray.count;
      });
    },
  },
};
</script>
